import { css } from '@emotion/react'
import { MenuItem, Select } from '@mui/material'
import { memo } from 'react'
import { useSnapshot } from 'valtio'
import { flex } from '~/modules/AppLayout/FlexGridCss'
import { ChartingModule } from '~/modules/SDK/chart4/ChartingModule'

export const ChartingServerSwitch = memo<
  ReactProps<{
    charting: ChartingModule
  }>
>(function ChartingServerSwitch(props) {
  const state = useSnapshot(props.charting)

  return (
    <span
      //若無設定寬高 則Select-ui會跑版(原生樣式)
      css={css`
        #__next & {
          ${flex.h.default};
          box-sizing: border-box;
          padding: 4px;
        }
      `}
    >
      <Select
        className={props.className}
        size='small'
        value={state.server?.historyUrl || ''}
        renderValue={value => {
          return state.server?.label
        }}
        onChange={event => {
          const url = event.target.value
          const found = state.serverList?.find(_server => _server.historyUrl === url)

          if (found) {
            props.charting.switchServer(found)
          }
        }}
        //若物件設定寬高為100% 則會依賴外層寬高
        css={css`
          width: 100%;
          height: 100%;
        `}
      >
        {state.serverList?.map((server, index) => {
          return (
            <MenuItem
              key={index}
              value={server.historyUrl}
            >
              {server.label}
            </MenuItem>
          )
        })}
      </Select>
    </span>
  )
})
