import { css } from '@emotion/react'

export const quoteBasicStyled = {
  containerBasic: css`
    &.dark {
      background-color: #343434;
      border: 1px solid #343434;
      color: #ffffff;
      &:hover {
        background-color: #444444;
      }
    }
    &.light {
      background-color: #f5f5f5;
      border: 1px solid #f5f5f5;
      color: #252525;
      &:hover {
        background-color: #eeeeee;
      }
    }

    //被選中的狀態樣式
    &.dark-active {
      background-color: #3c3c3c;
      color: #ffffff;
      border: 1px solid #aaaaaa;
    }
    &.light-active {
      background-color: #f1f1f1;
      color: #252525;
      border: 1px solid #aaaaaa;
    }
  `,
  containerWithBorder: css`
    &.dark {
      background-color: #131416;
      border: 1px solid #3c3c3c;
      color: #ffffff;
      &:hover {
        background-color: #202020;
      }
    }
    &.light {
      background-color: #fefefe;
      border: 1px solid #ebebeb;
      color: #252525;
      &:hover {
        background-color: #fafafa;
      }
    }
    &.dark-active {
      background-color: #232020;
      border: 1px solid #eeeeee;
      color: #ffffff;
      &:hover {
        background-color: #1f1f1f;
      }
    }
    &.light-active {
      background-color: #fafafa;
      border: 1px solid #888888;
      color: #252525;
      &:hover {
        background-color: #fafafa;
      }
    }
  `,
  valueUpFill: css`
    &.dark {
      color: #fb0000;
    }
    &.light {
      color: #aa0000;
    }
  `,
  valueDnFill: css`
    &.dark {
      color: #00ff00;
    }
    &.light {
      color: #009900;
    }
  `,
  valueDefaultFill: css`
    &.dark {
      color: #ffffff;
    }
    &.light {
      color: #252525;
    }
  `,
  valueVolumeFill: css`
    &.dark {
      color: #ffff00;
    }
    &.light {
      color: #ffaa00;
    }
  `,
}
