import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { memo } from 'react'
import { BsCircleFill } from 'react-icons/bs'
import { useThemeStore } from '~/components/theme/useThemeStore'
import { Signalr } from '~/modules/SDK/Signalr/Signalr'
import { ChangeNumber, processQuoteToReadable } from '~/utils/processQuoteToReadable'
import { quoteBasicStyled } from '../symbolList/css/quoteBasicStyled'

const convertUnit = (value: number) => {
  if (value >= 1e8) {
    return (value / 1e8).toFixed(2) + '億'
  } else return value
}

/** 顯示商品的報價 */
const QuoteValueBody = memo<
  ReactProps<{
    quote: Signalr.ValueOfOHLC | undefined
    ohlcType: keyof Signalr.ValueOfOHLC
    setFill?: 'up' | 'dn'
  }>
>(function QuoteValueBody(props) {
  const theme = useThemeStore(s => s.theme)
  const quote = props.quote?.[props.ohlcType] ?? 0
  const previous = props.quote?.prevRef ?? 0

  const qouteFill =
    Number(quote) >= previous ? quoteBasicStyled.valueUpFill : quoteBasicStyled.valueDnFill

  /** 若沒有指定方向顏色,就由選擇的報價類別做基準 */
  const setFill =
    props.setFill === 'up'
      ? quoteBasicStyled.valueUpFill
      : props.setFill === 'dn'
      ? quoteBasicStyled.valueDnFill
      : qouteFill

  if (props.ohlcType.includes('volume')) {
    return (
      //成交量報價
      <classes.container
        css={quoteBasicStyled.valueVolumeFill}
        className={theme}
      >
        {convertUnit(props.quote?.['volume'] ?? 0)}
      </classes.container>
    )
  } else
    return (
      //一般報價
      <classes.container
        css={setFill ?? qouteFill}
        className={theme}
      >
        {quote}
      </classes.container>
    )
})

/** 顯示商品的報價變動百分比 */
const QuoteChangeBody = memo<
  ReactProps<{
    quote: Signalr.ValueOfOHLC | undefined
    changeType: keyof ChangeNumber
    unit?: '%'
    displaySign?: boolean
  }>
>(function QuoteChangeBody(props) {
  const theme = useThemeStore(s => s.theme)
  const displaySign = props.displaySign ?? true
  const unit = props.unit

  const changes = processQuoteToReadable(props.quote as Signalr.ValueOfOHLC)
  const changeType = props.changeType
  const change = changes[changeType]
  const sign = Number(change) >= 0 ? '+' : null

  const qouteFill =
    Number(change) >= 0 ? quoteBasicStyled.valueUpFill : quoteBasicStyled.valueDnFill

  return (
    <classes.container
      css={qouteFill}
      className={theme}
    >
      {displaySign && sign}
      {Number(change).toFixed(2)}
      {unit}
    </classes.container>
  )
})

/** 顯示商品的報價變成交量 預設`closeChange` */
const QuoteArrowBody = memo<
  ReactProps<{ quote: Signalr.ValueOfOHLC | undefined; changeType?: keyof ChangeNumber }>
>(function QuoteArrowItem(props) {
  const theme = useThemeStore(s => s.theme)

  const changes = processQuoteToReadable(props.quote as Signalr.ValueOfOHLC)
  const changeType = props.changeType ?? 'closeChange'
  const change = changes[changeType]

  const qouteFill =
    Number(change) >= 0 ? quoteBasicStyled.valueUpFill : quoteBasicStyled.valueDnFill
  const arrow =
    Number(change) > 0 ? (
      '▲'
    ) : Number(change) < 0 ? (
      '▼'
    ) : (
      <BsCircleFill
        fontSize={8}
        fill={'#cccc00'}
        css={css`
          margin: 0 2px;
        `}
      />
    )

  return (
    <classes.container
      css={qouteFill}
      className={theme}
    >
      {arrow}
    </classes.container>
  )
})

const classes = {
  container: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
  `,
}

export default {
  ohlc: { Display: QuoteValueBody },
  change: { Display: QuoteChangeBody },
  arrow: { Display: QuoteArrowBody },
  classes,
}
