import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Fragment, memo } from 'react'
import useMedia from '~/hooks/useMedia'
import { flex } from '~/modules/AppLayout/FlexGridCss'
import { UserAvatarAsDialogButton } from '~/modules/auth/components/UserAvatarAsDialogButton'
import AgentFavicon from '~/pages/heineken_template/components/AgentFavicon'
import { store } from '~/pages/heineken_template/_private/store'
import { useElementAsComponent } from '~/pages/heineken_template/_private/useElementAsComponent'
import { ChartingServerSwitch } from '~/modules/SDK/chart4/components/ChartingServerSwitch'

const classes = {
  Root: styled.div``,
  LeftRoot: styled.div``,
  RightRoot: styled.div``,
}

const defaultsCssset = css`
  &${classes.Root} {
    ${flex.h.allCenter};
    width: 100%;
    justify-content: space-between;
    padding: 0 8px;
  }

  ${classes.LeftRoot} {
    ${flex.h.allCenter};
    gap: 8px;
  }

  ${classes.RightRoot} {
    ${flex.h.allCenter};
    gap: 8px;
  }
`

export const Preset_Topbar = memo<
  ReactProps<{
    /**
     * # 不顯示在「PC 視界」
     *
     * ## 有些投顧版面，「PC 視界」不需要顯示 Topbar；但卻會需要在「手機視界」顯示 Topbar
     */
    hideIfPcView?: boolean
    showLeftBurger?: boolean
    showRightBurger?: boolean

    /**
     * # 自訂你要的組件們
     *
     * @example
     *   // 只放入一個的組件
     *
     *   componentsInRight = {Row_UserAvatarAndChartingServers.Display}
     *
     * @example
     *   // 放入多個不同的組件
     *
     *   componentsInRight = {
     *     function() {
     *       return (
     *         <Fragment>
     *           <Row_UserAvatarAndChartingServers.Display />
     *           <Row_UserAvatarAndChartingServers.Display />
     *         </Fragment>
     *       )
     *     },
     *   }
     */
    componentsInRight?: React.ComponentType | React.ReactElement

    /** 註釋範例：參考 props.componentsInRight */
    componentsInLeft?: React.ComponentType | React.ReactElement
  }>
>(function Topbar(props) {
  const { isPc } = useMedia()
  const ComponentsInRight = useElementAsComponent(props.componentsInRight)
  const ComponentsInLeft = useElementAsComponent(props.componentsInLeft)

  if (props.hideIfPcView && isPc) {
    return null
  }

  return (
    <classes.Root
      className={props.className}
      css={defaultsCssset}
    >
      <classes.LeftRoot>
        {isPc && <AgentFavicon.Display />}
        {!isPc && props.showLeftBurger && (
          <store.drawerLeft.DrawerToggle
            css={css`
              height: 48px;
              width: 48px;
            `}
          />
        )}

        <ComponentsInLeft />
      </classes.LeftRoot>

      <classes.RightRoot>
        <ComponentsInRight />

        {!isPc && props.showRightBurger && (
          <store.drawerRight.DrawerToggle
            css={css`
              height: 48px;
              width: 48px;
            `}
          />
        )}
      </classes.RightRoot>
    </classes.Root>
  )
})
