import { css } from '@emotion/react'
import styled from '@emotion/styled'
import React, { memo, useEffect } from 'react'
import { useSnapshot } from 'valtio'
import { flex } from '~/modules/AppLayout/FlexGridCss'
import { useEstimatedTotalVolume } from '~/modules/screener/containers/useStockScreenerResource'
import { signalrStore2 } from '~/modules/SDK/Signalr/signalrStore2'
import QuoteValueItem from '~/modules/investment-consultant/quoteValueBody/QuoteValueBody'
import { fr_instrument } from '~/pages/heineken_template/_fr/fr_instrument'

/** 商品 漲跌點數 */
const Default = memo<ReactProps<{ symbol: string }>>(function Default(props) {
  const symbolNumber = props.symbol

  useEffect(() => {
    signalrStore2.addQuote(props.symbol)
    return () => {
      signalrStore2.removeQuote(props.symbol)
    }
  }, [props.symbol])

  const quote = useSnapshot(signalrStore2.values.quote)[props.symbol]

  return (
    <classes.container>
      <classes.name>
        {symbolNumber}
        {fr_instrument.getName(props.symbol)}
      </classes.name>

      <classes.quoteValue>
        <QuoteValueItem.arrow.Display quote={quote} />
        <QuoteValueItem.ohlc.Display
          quote={quote}
          ohlcType='close'
        />
      </classes.quoteValue>

      <classes.quoteValue>
        <QuoteValueItem.change.Display
          quote={quote}
          changeType='closeChange'
        />
      </classes.quoteValue>

      <classes.quoteValue>
        <QuoteValueItem.change.Display
          quote={quote}
          changeType='closeChangePercent'
          unit='%'
        />
      </classes.quoteValue>
    </classes.container>
  )
})

/** 商品 漲跌點數 */
const DefaulVolume = memo<ReactProps<{ symbol: string }>>(function DefaulVolume(props) {
  const symbolNumber = props.symbol

  useEffect(() => {
    signalrStore2.addQuote(props.symbol)
    return () => {
      signalrStore2.removeQuote(props.symbol)
    }
  }, [props.symbol])

  const quote = useSnapshot(signalrStore2.values.quote)[props.symbol]

  return (
    <classes.container>
      <classes.name>
        {symbolNumber}
        {fr_instrument.getName(props.symbol)}
      </classes.name>

      <classes.quoteValue>
        <QuoteValueItem.arrow.Display quote={quote} />
        <QuoteValueItem.ohlc.Display
          quote={quote}
          ohlcType='close'
        />
      </classes.quoteValue>

      <classes.quoteValue>
        量:
        <QuoteValueItem.ohlc.Display
          quote={quote}
          ohlcType='volume'
        />
      </classes.quoteValue>
    </classes.container>
  )
})

/** 商品 漲跌點數 預估量 單位`'口'|'張'|'億'` */
const EstimatedVol = memo<ReactProps<{ symbol: string; days?: number; unit: '口' | '張' | '億' }>>(
  function EstimatedVol(props) {
    const symbolNumber = props.symbol
    const day = props.days ?? 10
    const unit = props.unit
    const vol =
      useEstimatedTotalVolume({
        symbol: symbolNumber,
        days: day,
      }).data?.estimated_total_volume ?? NaN

    const value = vol > 1e8 ? vol / 1e8 : vol

    useEffect(() => {
      signalrStore2.addQuote(props.symbol)
      return () => {
        signalrStore2.removeQuote(props.symbol)
      }
    }, [props.symbol])

    const quote = useSnapshot(signalrStore2.values.quote)[props.symbol]

    return (
      <classes.container>
        <classes.name>
          {symbolNumber}
          {fr_instrument.getName(props.symbol)}
        </classes.name>

        <classes.quoteValue>
          <QuoteValueItem.change.Display
            quote={quote}
            changeType='closeChange'
          />
        </classes.quoteValue>

        <classes.quoteValue>
          預估量: {value.toFixed(0)}
          {unit}
        </classes.quoteValue>
      </classes.container>
    )
  },
)

/** 商品 漲跌點數 */
const Ohlc = memo<ReactProps<{ symbol: string }>>(function Ohlc(props) {
  const symbolNumber = props.symbol

  useEffect(() => {
    signalrStore2.addQuote(props.symbol)
    return () => {
      signalrStore2.removeQuote(props.symbol)
    }
  }, [props.symbol])

  const quote = useSnapshot(signalrStore2.values.quote)[props.symbol]

  return (
    <classes.container>
      <classes.name>
        {symbolNumber}
        {fr_instrument.getName(props.symbol)}
      </classes.name>

      <classes.quoteValue>
        開
        <QuoteValueItem.ohlc.Display
          quote={quote}
          ohlcType='open'
        />
      </classes.quoteValue>

      <classes.quoteValue>
        高
        <QuoteValueItem.ohlc.Display
          quote={quote}
          ohlcType='high'
          setFill='up'
        />
      </classes.quoteValue>

      <classes.quoteValue>
        低
        <QuoteValueItem.ohlc.Display
          quote={quote}
          ohlcType='low'
          setFill='dn'
        />
      </classes.quoteValue>
      <classes.quoteValue>
        收
        <QuoteValueItem.ohlc.Display
          quote={quote}
          ohlcType='close'
        />
      </classes.quoteValue>
    </classes.container>
  )
})

const classes = {
  container: styled.div`
    ${flex.h.crossCenter};
    width: 100%;
    height: 26px;
    font-size: 14px;
    justify-content: space-between;
    padding: 5px;
  `,
  name: styled.div``,
  quoteValue: styled.div`
    ${flex.h.allCenter}
  `,
}

export default {
  /** 格式：商品名稱 價格 變動 */
  Default,
  /** 格式：商品名稱 變動 預估量 */
  EstimatedVol,
  /** 格式：商品名稱 價格 成交量 */
  DefaulVolume,
  /** 格式： 開 高 低 收 */
  Ohlc,
}
